import {baseRequestClient3} from "@/request/request_client";
const requestClient = baseRequestClient3
export default class apiTalent {
    static signList = async (data: any) => {
        return await requestClient.get('/account.chinaums/index', data)
    }
    static applyQquery = async (data: any) => {
        return await requestClient.post('/account.chinaums/apply_query', data)
    }
    static chinaumsSave = async (data: any) => {
        return await requestClient.post('/account.chinaums/save', data)
    }
    static companyAccountVerify = async (data: any) => {
        return await requestClient.post('/account.chinaums/company_account_verify', data)
    }
    static requestAccountverify = async (data: any) => {
        return await requestClient.post('/account.chinaums/request_account_verify', data)
    }
    static agreementSign = async (data: any) => {
        return await requestClient.post('/account.chinaums/agreement_sign', data)
    }
}