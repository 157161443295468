
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import router from "@/router";
    import {PlusOutlined,CloseCircleOutlined} from '@ant-design/icons-vue'
    import Apisign from "@/request/apis/api_sign";
    interface IState {
        list:any,
        searches:any,
        total:any,
        detailData:any,
        visible:any ,
        certificationData:any,
        cervisible:any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent,PlusOutlined,CloseCircleOutlined},
        setup() {
            const state: IState = reactive({
                list:[],
                searches:{
                    page:PageUtil.pageNo,
                    pageSize:PageUtil.pageSize,
                    name:''
                },
                total:0,        
                detailData:"",
                visible:false ,
                certificationData:''  ,
                cervisible:false 
            })

            const columns = [
                {
                    title: 'LID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '签约流水号',
                    dataIndex: 'ums_reg_id',
                    key: 'ums_reg_id',
                    align:'center'
                },
                {
                    title: '注册类型',
                    dataIndex: 'reg_mer_type',
                    key: 'reg_mer_type',
                    align:'center'
                },
                {
                    title: '商户名称',
                    key: 'shop_name',
                    dataIndex: 'shop_name',
                    align:'center'
                },
                {
                    title: '账户类型',
                    key: 'bank_acct_type',
                    dataIndex: 'bank_acct_type',
                    align:'center'
                },
                {
                    title: '开户行帐号',
                    key: 'bank_acct_no',
                    dataIndex: 'bank_acct_no',
                    align:'center'
                },
                {
                    title: '法人姓名',
                    key: 'legal_name',
                    dataIndex: 'legal_name',
                    align:'center'
                },
                {
                    title: '签约状态',
                    key: 'apply_status_msg',
                    dataIndex: 'apply_status_msg',
                    align:'center'
                },  
                {
                    title: '创建时间',
                    key: 'create_time',
                    dataIndex: 'create_time',
                    align:'center'
                },                                         
                {
                    title: '操作',
                    key: 'action',
                    width: 350,
                    align:'center'
                },
            ];

            const columns2 = [
                {
                    title: '多应用商户号',
                    dataIndex: 'mapp_no',
                    key: 'mapp_no',
                    align:'center'
                },
                {
                    title: '多应用终端号列表',
                    dataIndex: 'term_app_no_list',
                    key: 'term_app_no_list',
                    align:'center'
                },
                {
                    title: '全民付二维码URL',
                    dataIndex: 'qrcodeQmfUrl',
                    key: 'qrcodeQmfUrl',
                    align:'center'
                },
                {
                    title: '云闪付二维码URL',
                    key: 'qrcodeYlUrl',
                    dataIndex: 'qrcodeYlUrl',
                    align:'center'
                },
                {
                    title: '卡种费率列表',
                    key: 'card_type_fee_list',
                    dataIndex: 'card_type_fee_list',
                    align:'center',
                    width:900
                },
            ];

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.pageSize,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async()=>{
                const params = {...state.searches}
                const res = await Apisign.signList(params)
                if(res.code == 1){
                    const data:any = res.data
                    state.list = data.data
                    state.total = data.total
                }else{
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.pageSize = pages.pageSize
                state.searches.page = pages.current
                getList()
            };
            
            const addApply = () => {
                router.push('./signAdd')
            }

            const getdetail = async (record) => {
                const params = {ums_reg_id:record.ums_reg_id}
                const res = await Apisign.applyQquery(params)
                console.log(res,123123)
                if(res.code == 1){
                    const data:any = res.data
                    state.visible = true
                    state.detailData = data
                }else{
                    message.error(res.msg)
                }
            }

            const accountfication = async  (record) => {
                const params = {ums_reg_id:record.ums_reg_id,company_account:record.bank_acct_no}
                const res = await Apisign.requestAccountverify(params)
                if(res.code == 1){
                    const data:any = res.data
                   getList()
                }else{
                    message.error(res.msg)
                }
            }

            const certification = (record) => {
                state.cervisible = true
                state.certificationData={
                    ums_reg_id:record.ums_reg_id || '',
                    company_account:record.bank_acct_no || '',
                    trans_amt:''
                }
                
            }

            const rules = {
                ums_reg_id: [{required: true, message: "请输入自助签约流水号"}],
                company_account:[{required: true, message: "请输入自助签约对公账号"}],
                trans_amt:[{required: true, message: "请输入交易金额"}],
            }
            const formRef = ref();
            const onSubmit = async () => {
                formRef.value.validate() .then(async() => {
                    const params = {...state.certificationData}
                    const res = await Apisign.companyAccountVerify(params)
                        if(res.code == 1){
                            message.error(res.msg)
                            getList()
                        }else{
                            message.error(res.msg)
                    }
                })
                .catch((error: any) => {
                    console.log("error", error)
                })
            }

            const Sign = async (record) => {
                const params = {company_account:record.bank_acct_no,ums_reg_id:record.ums_reg_id}
                const res = await Apisign.agreementSign(params)
                        if(res.code == 1){
                            const data :any = res.data
                            window.open(data.sign_url)
                        }else{
                            message.error(res.msg)
                }
            }

            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                onSubmit,
                Sign,
                rules,
                addApply,
                formRef,
                pagination,
                columns,
                certification,
                handleTableChange,
                getdetail,
                accountfication,
                columns2
            }
        }
    })
